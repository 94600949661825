export const environment = {
  production: true,
  env: "nh",
  appTitle: "New Holland - In-Cab Support",
  themeDirectory: "nh",
  shareUrl: "https://plmincabsupport.newholland.com",
  baseUrl: "https://new-holland-prod.herokuapp.com/api",
  basicsEquipmentCollection: "The Basics Articles - Equipment",
  basicsProductsCollection: "The Basics Articles - PLM Products",
  basicsEquipmentSlug: "the-basics-equipment",
  basicProductsSlug: "the-basics-plm-products",
  defaultLanguageSlug: "en-us",
  defaultRegionID: "276",
  equipmentCategorySlug: "equipment",
  productsCategorySlug: "plm-products",
  equipmentKey: "equipment",
  productsKey: "plm-products",
  equipmentArticles: "equipment-articles",
  productsArticles: "plm-product-articles",
  cacheUrl:
    "https://s3.us-east-2.amazonaws.com/in-cab-support/daily_data_dumps/",
  cacheEnv: "nh-prod",
  tempDefaultHowToEquipment: "8",
  tempDefaultHowToProduct: "22",
  tempDefaultHowToEquipmentTitle: "T-8 Series",
  tempDefaultHowToProductTitle: "IntelliView 12",
  privacyPolicyPageID: "31",
  termsAndConditionsPageID: "32",
  dataStoragePolicyPageID: "38",
  eulaPageID: "32",
  operatorsManualNoticePageID: "79",
  mappPageID: "31",
  googleAnalyticsKey: "G-ML8JHRE27J",
  awsBucket: "in-cab-support",
  googleGeocodingAPIKey: "AIzaSyDKwBZKSAecY90e_nR2kl6Ne3QvQrrSvbY",
};
